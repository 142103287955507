import { ArrowUpRight as ArrowUpRightIcon } from 'lucide-react'
import { useCallback } from 'react'
import Image from 'next/future/image'

import { analytics } from 'utils/analytics'
import logo from 'public/logo-with-text.svg'

function Footer() {
  const handlePrismaLinkClick = useCallback(() => {
    analytics.track('prisma footer link click')
  }, [])

  return (
    <div className="
      hidden
      md:flex
      items-center
      justify-start
      px-4
      py-2
      bg-gray-900
      border-t
      border-gray-800
    ">
      <a
        className="
          flex
          items-end
          space-x-2
        "
        href="https://prisma.io"
        rel="noreferrer noopener"
        target="_blank"
        onClick={handlePrismaLinkClick}
      >
        <Image
          alt="Prisma logo"
          src={logo}
        />
        <ArrowUpRightIcon
          className="
            text-white
            relative
            bottom-px
          "
        />
      </a>
    </div>
  )
}

export default Footer
