import { ReactNode } from 'react'
import cn from 'classnames'

export enum Size {
  '5xl',
  '3xl',
  '2xl',
  xl,
  lg,
  base,
  sm,
  xs,
}

const Sizes = {
  [Size['5xl']]: 'text-5xl',
  [Size['3xl']]: 'text-3xl',
  [Size['2xl']]: 'text-2xl',
  [Size.xl]: 'text-xl',
  [Size.lg]: 'text-lg',
  [Size.base]: 'text-base',
  [Size.sm]: 'text-sm',
  [Size.xs]: 'text-xs',
}

export enum Typeface {
  InterRegular,
  InterMedium,
  InterSemibold,
  InterBold,

  BarlowMedium,
  BarlowSemibold,
  BarlowBold,

  MonoRegular,
  MonoBold,
}

const Typefaces = {
  [Typeface.InterRegular]: 'font-inter font-regular',
  [Typeface.InterMedium]: 'font-inter font-medium',
  [Typeface.InterSemibold]: 'font-inter font-semibold',
  [Typeface.InterBold]: 'font-inter font-bold',

  [Typeface.BarlowMedium]: 'font-barlow font-medium',
  [Typeface.BarlowSemibold]: 'font-barlow font-semibold',
  [Typeface.BarlowBold]: 'font-barlow font-bold',

  [Typeface.MonoRegular]: 'font-mono',
  [Typeface.MonoBold]: 'font-mono font-bold',
}

interface Props {
  className?: string
  children?: ReactNode
  text?: string | ReactNode
  size?: Size | null
  onClick?: (e: any) => void
  typeface?: Typeface
  hoverTypeface?: Typeface
  color?: string
  hoverColor?: string
}

function Text({
  className,
  text,
  onClick,
  size = Size.sm,
  typeface = Typeface.InterRegular,
  hoverTypeface,
  color = 'text-gray-800 dark:text-gray-100',
  hoverColor,
  children,
}: Props) {
  const s = size !== null ? Sizes[size] : undefined
  return (
    <span
      className={cn(
        s,
        Typefaces[typeface],
        color,
        hoverColor,
        hoverTypeface && Typefaces[hoverTypeface],
        className
      )}
      onClick={onClick}
    >
      {text || children}
    </span>
  )
}

Text.size = Size
Text.typeface = Typeface
export default Text
