import {
  ReactNode,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

import { Example } from 'utils/examplesContent'
import { Guide } from 'utils/guidesContent/Guide'
import { TOCPage } from 'components/TOCSidebar/TOCPage'
import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import TOCSidebar from 'components/TOCSidebar'
import type { Breadcrumb } from 'components/Breadcrumbs'

export interface PageMeta {
  title: string
  description: string
  cardImage: string
}

interface Props {
  children: ReactNode
  meta?: PageMeta
  example?: Example
  guide?: Guide
  category?: TOCPage
  subcategory?: TOCPage
  toc?: TOCPage[]
}

export default function Layout({
  children,
  meta: pageMeta,
  example,
  guide,
  subcategory,
  category,
  toc,
}: Props) {
  const router = useRouter()
  const [isTocOpened, setIsTocOpened] = useState(false)

  let pageTitle = ''
  let navbarTitle = ''
  let breadcrumbs: Breadcrumb[] = []

  if (router.pathname === '/') {
    pageTitle = 'Prisma Playground | Prisma'
    navbarTitle = 'Prisma Playground'
  } else if (router.pathname === '/examples/[categoryID]/[subcategoryID]/[exampleID]') {
    pageTitle = `${example?.title} | Prisma Client`
    // navbarTitle = 'Prisma Client'
    breadcrumbs = [
      {
        title: 'Prisma Client',
        href: '/examples',
      },
      {
        title: category?.title,
        href: `/examples/${example?.categoryID}/${example?.subcategoryID}/${example?.id}`,
      },
      {
        title: subcategory?.title,
        href: `/examples/${example?.categoryID}/${example?.subcategoryID}/${example?.id}`,
      },
      {
        title: example?.title,
        href: `/examples/${example?.categoryID}/${example?.subcategoryID}/${example?.id}`,
      },
    ]
  } else if (router.pathname === '/guides') {
    pageTitle = 'Prisma Interactive Guides'
    // navbarTitle = 'Prisma Guides'
    breadcrumbs = [
      {
        title: 'Prisma Migrate',
        href: '/guides',
      },
    ]
  } else if (router.pathname === '/guides/[guideID]') {
    pageTitle = `${guide?.intro.title} | Prisma Guides`
    // navbarTitle = 'Prisma Guides'
    breadcrumbs = [
      {
        title: 'Prisma Migrate',
        href: '/guides',
      },
      {
        title: `How to ${guide?.intro.title.toLowerCase()}`,
        href: `/guides/${guide?.id}`,
      },
    ]
  }

  const meta = {
    // title: pageTitle,
    title: 'Prisma Playground | Learn the Prisma ORM in your browser',
    description: 'The Playground is an interactive learning environment for Prisma. Learn how to send database queries and explore migrations workflows with the Prisma ORM.',
    cardImage: '/og-image.png',
    ...pageMeta,
  }

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta
          content="follow, index"
          name="robots"
        />
        <link
          href="/favicon.png"
          rel="shortcut icon"
        />
        <meta
          content={meta.description}
          name="description"
        />
        <meta
          content={`https://prismabyexample.com${router.asPath}`}
          property="og:url"
        />
        <meta
          content="website"
          property="og:type"
        />
        <meta
          content={meta.title}
          property="og:site_name"
        />
        <meta
          content={meta.description}
          property="og:description"
        />
        <meta
          content={meta.title}
          property="og:title"
        />
        {/* <meta
          content={meta.cardImage}
          property="og:image"
        /> */}
        <meta
          content="https://prisma.usedevbook.com/og-image.png"
          property="og:image"
        />
        <meta
          content="summary_large_image"
          name="twitter:card"
        />
        <meta
          content="@prisma"
          name="twitter:site"
        />
        <meta
          content={meta.title}
          name="twitter:title"
        />
        <meta
          content={meta.description}
          name="twitter:description"
        />
        {/* <meta
          content={meta.cardImage}
          name="twitter:image"
        /> */}
        <meta
          content="https://prisma.usedevbook.com/og-image.png"
          property="twitter:image:src"
        />
        <meta
          content="https://prisma.usedevbook.com/og-image.png"
          property="twitter:image"
        />
      </Head>

      <div className="
        flex
        h-screen
        w-screen
        flex-col
        overflow-hidden
      ">
        <Navbar
          breadcrumbs={breadcrumbs}
          title={navbarTitle}
          onOpenTocClick={() => setIsTocOpened(val => !val)}
        />
        <main className="
          flex
          flex-1
          overflow-hidden
        ">
          {/* Disable sidebar in guides */}
          {toc && !router.pathname.startsWith('/guides') &&
            <TOCSidebar
              isOpened={isTocOpened}
              toc={toc}
              onCloseClick={() => setIsTocOpened(false)}
            />
          }
          {children}
        </main>

        <Footer/>
      </div>
    </>
  )
}
