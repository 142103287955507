import { DevbookPlugin } from './devbookPlugin'
import { originalSourcePlugin } from '@analytics/original-source-plugin'
import Analytics from 'analytics'

import googleAnalytics from '@analytics/google-analytics'

export const appID = process.env.NODE_ENV === 'development' ? 'test' : 'prisma-hub'

export const editFileDebounce = 3_000 // 3s

export const analytics = Analytics({
  app: appID,
  debug: process.env.NODE_ENV === 'development',
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  plugins: [
    originalSourcePlugin(),
    new DevbookPlugin({
      appID,
      dry: process.env.NODE_ENV === 'development',
      writeKey: appID,
    }),
    googleAnalytics({ measurementIds: ['G-BL7DM2JFBC', 'G-L62E7Q1RJD'] }),
  ],
})

// Add the current URL to the track event
analytics.on('trackStart', (data) => {
  const search = window.location.search
  data.payload.properties = {
    ...data.payload.properties,
    $url_path: window.location.pathname,
    $url_search: search && search.length > 0 ? search : undefined,
  }
})

export function getUser() {
  const userId: string | undefined = analytics.user('userId')
  const anonymousId: string | undefined = analytics.user('anonymousId')

  return {
    userId,
    anonymousId,
  }
}
