import { ChevronRight as ChevronRightIcon } from 'lucide-react'
import { useMediaQuery } from 'react-responsive'
import Link from 'next/link'
import React, {
  useEffect,
  useState,
} from 'react'

import Text from 'components/typography/Text'

export interface Breadcrumb {
  title?: string
  href?: string
}

export interface Props {
  breadcrumbs: Breadcrumb[]
}

function Breadcrumbs({ breadcrumbs }: Props) {
  const [isMounted, setIsMounted] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  // Show only the last part of breadcrumbs on mobile.
  const adjustedBreadcrumbs = isMobile && breadcrumbs.length > 0 ? [breadcrumbs[breadcrumbs.length - 1]] : breadcrumbs

  useEffect(function mount() {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null
  return (
    <div
      className="
        flex
        items-center
        space-x-1
      "
    >
      {adjustedBreadcrumbs.map((b, idx) => (
        <React.Fragment key={idx}>
          {b.title &&
            <>
              {b.href ? (
                <Link
                  href={b.href}
                >
                  <a className="no-underline">
                    <Text
                      className="transition-all"
                      color={idx < adjustedBreadcrumbs.length - 1 ? 'text-gray-500' : 'text-indigo-500'}
                      hoverColor="hover:text-indigo-500"
                      text={b.title}
                      typeface={idx < adjustedBreadcrumbs.length - 1 ? Text.typeface.InterRegular : Text.typeface.InterBold}
                    />
                  </a>
                </Link>
              ) : (
                <Text
                  color={idx < adjustedBreadcrumbs.length - 1 ? 'text-gray-500' : 'text-indigo-500'}
                  text={b.title}
                  typeface={idx < adjustedBreadcrumbs.length - 1 ? Text.typeface.InterRegular : Text.typeface.InterBold}
                />
              )}
              {idx < adjustedBreadcrumbs.length - 1 && (
                <ChevronRightIcon
                  className={idx === adjustedBreadcrumbs.length - 2 ? 'text-indigo-500 relative top-px' : 'text-gray-500 relative top-px'}
                  size={14}
                />
              )}
            </>
          }
        </React.Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs
