import {
  IRawGrammar,
  ParserState,
  useTextMateLanguages,
} from '@devbookhq/codemirror-textmate'
import {
  LanguageSetup,
  ServerCapabilities,
  getLanguageSetup,
} from '@devbookhq/code-editor'
import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { StreamLanguage } from '@codemirror/language'
import { json } from '@codemirror/lang-json'
import { sql as sqlLanguage } from '@codemirror/lang-sql'
import { typescriptLanguage } from '@codemirror/lang-javascript'

import prismaSyntax from './grammars/prisma.tmLanguage.json'

import prismaDefaultServerCapabilities from './languageServerCapabilities/prisma.json'
import typescriptDefaultServerCapabilities from './languageServerCapabilities/typescript.json'

export type Language = (LanguageSetup | {
  languageExtensions: StreamLanguage<ParserState>;
  languageServerCommand?: string | undefined;
  fileExtensions: string[];
  languageID: string;
  defaultServerCapabilities?: ServerCapabilities | undefined;
})

export enum LanguageID {
  Typescript = 'typescript',
  Prisma = 'prisma',
  Json = 'json',
  Sql = 'sql',
}

export const languageServerPort = 5523

export const supportedLanguages: LanguageSetup[] = [
  {
    // Necessary packages were installed by `npm i -g typescript-language-server typescript`
    languageServerCommand: 'typescript-language-server',
    fileExtensions: ['.js', '.ts'],
    languageID: LanguageID.Typescript,
    languageExtensions: typescriptLanguage,
    defaultServerCapabilities: typescriptDefaultServerCapabilities.result.capabilities as ServerCapabilities,
  },
  {
    // Necessary packages were installed by `npm i -g @prisma/language-server`
    languageServerCommand: 'prisma-language-server',
    fileExtensions: ['.prisma'],
    languageID: LanguageID.Prisma,
    defaultServerCapabilities: prismaDefaultServerCapabilities.result.capabilities as ServerCapabilities,
  },
  {
    fileExtensions: ['.sql'],
    languageID: LanguageID.Sql,
    languageExtensions: sqlLanguage(),
  },
  {
    fileExtensions: ['.json'],
    languageID: LanguageID.Json,
    languageExtensions: json(),
  },
]

export function getLanguage(filename: string) {
  return getLanguageSetup(filename, supportedLanguages)
}

const textMateGrammars = [prismaSyntax as unknown as IRawGrammar]

export const supportedLangaugesWithTextMateContext = createContext<Language[] | undefined>(undefined)
export interface SupportedLangaugesWithTextMateProviderProps {
  children: ReactNode
}
export function SupportedLangaugesWithTextMateProvider({ children }: SupportedLangaugesWithTextMateProviderProps) {
  const textMateLanguages = useTextMateLanguages({ textMateGrammars })

  const languages = useMemo(() => {
    if (!textMateLanguages) return supportedLanguages

    return supportedLanguages.map(s => {
      if (s.languageExtensions) return s

      const languageExtensions = textMateLanguages[`source.${s.languageID}`]
      if (!languageExtensions) return s

      return {
        ...s,
        languageExtensions,
      }
    })
  }, [textMateLanguages])

  return (
    <supportedLangaugesWithTextMateContext.Provider value={languages}>
      {children}
    </supportedLangaugesWithTextMateContext.Provider>
  )
}

export function useSupportedLangaugesWithTextMate() {
  const ctx = useContext(supportedLangaugesWithTextMateContext)
  if (ctx === undefined) {
    throw new Error('useSupportedLangaugesWithTextMate must be used within the SupportedLangaugesWithTextMateProvider')
  }
  return ctx
}
