import { Lightbulb as LightbulbIcon } from 'lucide-react'
import Image from 'next/future/image'
import Link from 'next/link'

import { analytics } from 'utils/analytics'
import Breadcrumbs from 'components/Breadcrumbs'
import InstallPrismaButton from 'components/InstallPrismaButton'
import Text from 'components/typography/Text'
import githubLogo from 'public/github.svg'
import logo from 'public/logo.svg'
import slackLogo from 'public/slack.svg'
import type { Breadcrumb } from 'components/Breadcrumbs'

export interface Props {
  title: string
  breadcrumbs: Breadcrumb[]
}

const githubDiscussionsURL = 'https://github.com/prisma/prisma/discussions'
const slackURL = 'https://slack.prisma.io/'

function DesktopNavbar({
  title,
  breadcrumbs,
}: Props) {
  return (
    <header className="
      hidden
      h-[56px]
      items-center
      justify-between
      px-4
      py-3
      lg:flex
      border-b
      border-gray-300
    ">
      <nav className="
        flex
        items-center
        space-x-3
      ">
        <Link
          href="/"
          passHref
        >
          <a className="
            flex
            h-[32px]
            w-[32px]
            items-center
            rounded-md
            bg-gray-700
            p-[7px]
          ">
            <Image
              alt="Prisma logo"
              src={logo}
            />
          </a>
        </Link>
        {title &&
          <Text
            text={title}
            typeface={Text.typeface.InterSemibold}
          />
        }
        {breadcrumbs.length > 0 && (
          <>
            {/* Vertical separator */}
            <div className="
              h-[24px]
              w-px
              rounded
              bg-gray-300
            "/>
            {/* *** */}
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </>
        )}
      </nav>
      <div className="
        flex
        items-center
        space-x-8
      ">
        <div className="
          relative
          z-50
          group
          transition-all
          ease
          duration-100
          cursor-pointer
        ">
          <div className="
            flex
            items-center
            space-x-1
          ">
            <LightbulbIcon
              className="
                text-gray-800
                group-hover:text-indigo-600
              "
              size={18}
              strokeWidth={2.5}
            />
            <Text
              className="cursor-pointer"
              color="text-gray-800"
              hoverColor="group-hover:text-indigo-600"
              size={Text.size.sm}
              text="Share Feedback"
              typeface={Text.typeface.InterMedium}
            />
          </div>

          <div className="
            transition-all
            ease
            duration-100
            opacity-0
            group-hover:opacity-100
            absolute
            top-[35px]
            inset-x-0
            h-[2px]
            bg-indigo-600
            rounded
          "/>

          <div className="
            transition-all
            ease
            duration-100
            hidden
            cursor-pointer
            group-hover:block
            absolute
            left-[-77.5px]
            top-0
            pt-[50px]
            w-[300px]
          ">
            <div className="
              bg-white
              border
              border-gray-300
              flex
              flex-col
              items-start
              justify-start
              p-4
              space-y-2
              cursor-default
              w-full
              shadow-lg
              rounded-lg
            ">
              <a
                className="flex items-center"
                href={githubDiscussionsURL}
                rel="noopener noreferrer"
                target="_blank"
                onMouseDown={() => {
                  analytics.track('link opened', { url: githubDiscussionsURL })
                }}
              >
                <Image
                  alt="GitHub logo"
                  className="
                    mr-[10px]
                    ml-[9px]
                    my-[9px]
                    w-[20px]
                    h-[20px]
                  "
                  src={githubLogo}
                />
                <Text
                  className="cursor-pointer"
                  color="text-gray-800"
                  hoverColor="hover:text-indigo-600"
                  size={Text.size.sm}
                  text="GitHub Discussions"
                  typeface={Text.typeface.InterMedium}
                />
              </a>

              <a
                className="flex items-center"
                href={slackURL}
                rel="noopener noreferrer"
                target="_blank"
                onMouseDown={() => {
                  analytics.track('link opened', { url: slackURL })
                }}
              >

                <Image
                  alt="Slack logo"
                  className="
                    w-[38px]
                    h-[38px]
                  "
                  src={slackLogo}
                />
                <Text
                  className="cursor-pointer"
                  color="text-gray-800"
                  hoverColor="hover:text-indigo-600"
                  size={Text.size.sm}
                  text="Join Slack Community"
                  typeface={Text.typeface.InterMedium}
                />
              </a>
            </div>
          </div>
        </div>
        <InstallPrismaButton />
      </div>
    </header>
  )
}

export default DesktopNavbar
