import { Menu as MenuIcon } from 'lucide-react'
import { useRouter } from 'next/router'
import Image from 'next/future/image'
import Link from 'next/link'
import React from 'react'

import Breadcrumbs from 'components/Breadcrumbs'
import Text from 'components/typography/Text'
import logo from 'public/logo.svg'
import type { Breadcrumb } from 'components/Breadcrumbs'

export interface Props {
  title: string
  breadcrumbs: Breadcrumb[]
  onOpenTocClick: (e: any) => void
}

function MobileNavbar({
  title,
  breadcrumbs,
  onOpenTocClick,
}: Props) {
  const router = useRouter()

  return (
    <header
      className="
        mb-[6px]
        flex
        w-full
        items-center
        justify-between
        px-2
        pt-1.5
        lg:hidden
      "
    >
      <div
        className="
          flex
          items-center
          space-x-1
          text-gray-600
        "
      >
        {router.pathname.startsWith('/examples') &&
          <div
            className="
              mr-2
              cursor-pointer
              text-gray-600
            "
            onClick={onOpenTocClick}
          >
            <MenuIcon
              color="currentColor"
              size={14}
            />
          </div>
        }
        {router.pathname === '/' && title &&
          <Text
            text={title}
            typeface={Text.typeface.InterSemibold}
          />
        }
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <Link
        href="/"
        passHref
      >
        <a
          className="
              h-[24px]
              w-[24px]
              items-center
              rounded-md
              bg-gray-700
              p-1.5
            "
        >
          <Image alt="Prisma logo" src={logo}/>
        </a>
      </Link>
    </header>
  )
}

export default MobileNavbar
