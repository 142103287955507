import { X as CrossIcon } from 'lucide-react'
import { TOCPage } from 'components/TOCSidebar/TOCPage'
import { useState } from 'react'
import cn from 'classnames'

import Page from './Page'

export interface Props {
  // Table of contents
  toc: TOCPage[]
  onCloseClick: (e: any) => void
  isOpened?: boolean
}

function TOCSidebar({
  toc,
  onCloseClick,
  // Users can show/hide the sidebar in the mobile version.
  isOpened,
}: Props) {
  // Users can minify the sidebar in the desktop version.
  const [isMinified, setIsMinified] = useState(false)

  return (
    <>
      {/* Mobile full-page background blur */}
      <div
        className={cn(
          { hidden: !isOpened },
          { fixed: isOpened },
          'lg:hidden',
          'z-20',
          'w-full',
          'inset-0',
          'top-[35px]',
          'md:top-[44px]',
          'backdrop-blur',
          'border-t',
          'border-gray-300',
        )}
        onClick={onCloseClick}
      />
      {/* *** */}

      <aside className={cn(
        { hidden: !isOpened },
        { fixed: isOpened },
        'lg:flex',
        'relative',
        'z-30',
        'pr-2',
        'pt-2',
        'bg-white',
        'border-r',
        'border-gray-300',
        'overflow-y-auto',
        'transition-all',
        'scroller',
        { 'w-auto': !isMinified },
        { 'max-w-none': !isMinified },
        { 'lg:w-[45px]': isMinified },
        { 'lg:max-w-[45px]': isMinified }
      )}>
        {/* Mobile button for closing the sidebar */}
        <button
          className="
            absolute
            top-[19px]
            right-4
            cursor-pointer
            text-gray-600
            lg:hidden
          "
          onClick={onCloseClick}
        >
          <CrossIcon
            color="currentColor"
            size={16}
          />
        </button>
        {/* *** */}

        {/* Hide/show sidebar */}
        {/* <button
          className={cn(
            'hidden',
            'lg:block',
            'absolute',
            'bottom-[12px]',
            {
              'right-[18px]': !isMinified,
              'right-[8px]': isMinified,
            },
            'p-1',
            'bg-white',
            'rounded-full',
            'border',
            'transition-all',
            'text-gray-500',
            'cursor-pointer',
          )}
          onClick={() => setIsMinified(val => !val)}
        >
          <ChevronLeftIcon
            className={cn(
              'transition-all',
              { 'rotate-0': !isMinified },
              { 'rotate-180': isMinified }
            )}
            size={24}
          />
        </button> */}
        {/* *** */}

        {!isMinified && (
          <nav
            className="
            px-4
            min-w-[190px]
            pb-3
            transition-all
          "
          >
            {toc.map(p => (
              <Page
                key={p.path}
                page={p}
                onLinkClick={onCloseClick}
              />
            ))}
          </nav>
        )}
      </aside>
    </>
  )
}

export default TOCSidebar
