import Image from 'next/future/image'
import logo from 'public/logo.svg'

import { analytics } from 'utils/analytics'
import Text from 'components/typography/Text'

const url = 'https://www.prisma.io/docs/getting-started/quickstart?utm_source=devbook'

function InstallPrismaButton() {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={url}
      target="_blank" onMouseDown={() => analytics.track('link opened', { url })}
    >
      <button className="
        group
        flex
        flex-row
        items-center
        justify-center
        rounded-md
        bg-gradient-to-r
        transition-all
      ">
        <div className="
          flex
          flex-row
          items-center
          justify-center
          space-x-2
          rounded-md
          bg-indigo-600
          px-3
          py-1.5
          transition-all
          group-hover:bg-indigo-700
        ">
          <Text
            color="text-gray-100"
            size={Text.size.sm}
            text="Get Started"
            typeface={Text.typeface.InterSemibold}
          />
          <Image
            alt="Logo"
            className="
              w-[18px]
              h-[18px]
              group-hover:animate-wiggle
            "
            src={logo}
          />
        </div>
      </button>
    </a>
  )
}

export default InstallPrismaButton
