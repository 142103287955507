var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://73764a68eeb34653816a3dae907593fc@o1382733.ingest.sentry.io/6698339',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  sampleRate: 0.05,
  normalizeMaxBreadth: 10,
  maxBreadcrumbs: 10,
  maxValueLength: 400,
  denyUrls: /^data:application\/wasm;base64*/,
  integrations: [new Sentry.BrowserTracing({})],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb === 'console') {
      return null
    }
    return breadcrumb
  },
})
