import DesktopNavbar from './Desktop'
import MobileNavbar from './Mobile'

import type { Breadcrumb } from 'components/Breadcrumbs'

export interface Props {
  title: string
  breadcrumbs: Breadcrumb[]
  onOpenTocClick: (e: any) => void
}

function Navbar({
  title,
  breadcrumbs,
  onOpenTocClick,
}: Props) {
  return (
    <>
      <MobileNavbar
        breadcrumbs={breadcrumbs}
        title={title}
        onOpenTocClick={onOpenTocClick}
      />

      <DesktopNavbar
        breadcrumbs={breadcrumbs}
        title={title}
      />
    </>
  )
}

export default Navbar
