import { useRouter } from 'next/router'
import Link from 'next/link'
import cn from 'classnames'

import { TOCPage } from 'components/TOCSidebar/TOCPage'
import Text from 'components/typography/Text'

export interface Props {
  page: TOCPage
}

function PageTitle({ page }: Props) {
  const {
    title,
    level,
    path,
    id: pageID,
    categoryID: pageCategoryID,
    subcategoryID: pageSubcategoryID,
  } = page

  // Currently opened page
  const {
    query: {
      categoryID,
      subcategoryID,
      exampleID,
    },
  } = useRouter()

  let isActive = false
  switch (level) {
  case 0:
    isActive = pageCategoryID === categoryID
    return (
      <Text
        color={'text-indigo-600'}
        size={Text.size.lg}
        text={title}
        typeface={Text.typeface.BarlowBold}
      />
    )
  case 1:
    isActive = pageCategoryID === categoryID
      && pageSubcategoryID === subcategoryID
    return (
      <Text
        color={'text-gray-600'}
        text={title}
        typeface={Text.typeface.BarlowBold}
      />
    )
  case 2:
    isActive = pageCategoryID === categoryID
      && pageSubcategoryID === subcategoryID
      && pageID === exampleID
    return (
      <Link
        href={{ pathname: path }}
        passHref
      >
        <a>
          <Text
            className={cn(
              'py-1',
              'pl-3',
              'ml-1',
              'cursor-pointer',
              'transition-colors',
              'border-l',
              { 'border-gray-300': !isActive },
              { 'border-indigo-500': isActive },
              'hover:border-indigo-500'
            )}
            color={isActive ? 'text-indigo-500' : 'text-gray-500'}
            hoverColor="hover:text-indigo-500"
            text={title}
            typeface={isActive ? Text.typeface.InterBold : Text.typeface.InterRegular}
          />
        </a>
      </Link>
    )

  default:
    return null
  }
}

export default PageTitle
