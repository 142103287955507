import { TOCPage } from 'components/TOCSidebar/TOCPage'
import cn from 'classnames'

import PageTitle from './PageTitle'

export interface Props {
  page: TOCPage
  onLinkClick: (e: any) => void
}

function Page({
  page: p,
  onLinkClick,
}: Props) {
  const hasSubpages = p.pages.length > 0
  return (
    <div className={cn({ 'my-2': p.level === 1 })}>
      <PageTitle
        page={p}
      />

      {hasSubpages &&
        p.pages.map(subpage => (
          <Page
            key={subpage.path}
            page={subpage}
            onLinkClick={onLinkClick}
          />
        ))}
    </div>
  )
}

export default Page
