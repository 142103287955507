import { useEffect } from 'react'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'

import { getUser } from './index'


export function usePostHog () {
  const router = useRouter()

  useEffect(() => {
    // Init PostHog
    if (process.env.NODE_ENV === 'development') return

    // This is a public key
    posthog.init('phc_qWSls6YZ2zCFNQj6g8QdHtKJOpSDovLOEAt6e6IwoLt', { api_host: 'https://app.posthog.com' })

    const {
      userId,
      anonymousId,
    } = getUser()

    if (userId || anonymousId) {
      posthog.identify(userId || anonymousId)
    }

    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
