import { Router } from 'next/router'
import { useEffect } from 'react'

import { analytics } from './index'

export interface UsePageViewsOptions {
  ignoreHashChange?: boolean
  disabled?: boolean
}

export function usePageViews({
  ignoreHashChange,
  disabled,
}: UsePageViewsOptions = {}): void {
  useEffect(function subscribeToRoute() {
    if (disabled) return

    analytics.page()
    const handleRouteChange = (url: URL) => {
      analytics.page()
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    if (!ignoreHashChange) {
      Router.events.on('hashChangeComplete', handleRouteChange)
    }

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)

      if (!ignoreHashChange) {
        Router.events.off('hashChangeComplete', handleRouteChange)
      }
    }
  }, [ignoreHashChange, disabled])
}
