import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import { useRouter } from 'next/router'
import cn from 'classnames'

import Text from 'components/typography/Text'

interface Theme {
  brightness: 'light' | 'dark'
  isCollapsed: boolean
}

function DevbookTag() {
  const router = useRouter()
  const [isMounted, setIsMounted] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  // Indicates what brightness the tag should have
  const theme: Theme = useMemo(() => {
    if (router.pathname === '/guides') {
      return {
        brightness: isMobile ? 'light' : 'dark',
        isCollapsed: isMobile,
      }
    } else if (router.pathname === '/guides/[guideID]') {
      return {
        brightness: isMobile ? 'light' : 'dark',
        isCollapsed: isMobile,
      }
    } else if (router.pathname === '/examples/[categoryID]/[subcategoryID]/[exampleID]') {
      return {
        brightness: isMobile ? 'light' : 'dark',
        isCollapsed: isMobile,
      }
    }
    return {
      brightness: isMobile ? 'light' : 'dark',
      isCollapsed: isMobile,
    }
  }, [router, isMobile])

  useEffect(function mount() {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null
  return (
    <a
      href="https://usedevbook.com?utm_source=prisma&utm_medium=guide"
      rel="noopener noreferrer"
      target="_blank"
    >
      <div
        className={cn(
          { 'py-1.5': !isMobile },
          { 'px-2': !isMobile },
          { 'hover:px-2': isMobile },
          { 'min-w-[26px]': isMobile },
          { 'h-[26px]': isMobile },
          { 'justify-center': isMobile },


          'fixed',
          { 'bottom-[9px]': isMobile },
          { 'bottom-[6px]': !isMobile },
          'right-[8px]',

          'transition-all',
          'flex',
          'items-center',
          'z-50',
          { 'rounded': isMobile },
          { 'rounded-lg': !isMobile },
          'cursor-pointer',
          'group',

          { 'bg-black': theme.brightness === 'dark' },
          { 'bg-white': theme.brightness === 'light' },

          { 'border-2': theme.brightness === 'dark' },
          { 'border': theme.brightness === 'light' },
          { 'border-green-500/30': theme.brightness === 'dark' },
          { 'border-gray-200': theme.brightness === 'light' },

          { 'drop-shadow': theme.brightness === 'light' },

          { 'hover:border-green-400': theme.brightness === 'dark' } ,
        )}
      >
        <div className="
          flex
          items-center
          space-x-[1px]
          -mr-px
        ">
          <Text
            color={theme.brightness === 'dark' ? 'text-green-500' : 'text-green-600'}
            size={Text.size.xs}
            text="["
            typeface={Text.typeface.MonoBold}
          />
          <Text
            color={theme.brightness === 'dark' ? 'text-green-500' : 'text-green-600'}
            size={Text.size.xs}
            text=")"
            typeface={Text.typeface.MonoBold}
          />
        </div>
        {theme.isCollapsed ? (
          <div className="
            w-0
            flex
            group-hover:w-[115px]
            group-hover:ml-1
            overflow-hidden
            transition-all
            ease-in-out
            items-center
          ">
            <Text
              className="truncate"
              color={theme.brightness === 'dark' ? 'text-white' : 'text-gray-800'}
              size={Text.size.xs}
              text="Made with Devbook"
              typeface={Text.typeface.InterMedium}
            />
          </div>
        ) : (
          <Text
            className="ml-1"
            color={theme.brightness === 'dark' ? 'text-white' : 'text-gray-800'}
            size={Text.size.xs}
            text="Made with Devbook"
            typeface={Text.typeface.InterMedium}
          />
        )}
      </div>
    </a>
  )
}

export default DevbookTag
